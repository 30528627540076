import React from "react";

import Sign2xPath from "@images/logo/sign.2x.png";
import Sign3xPath from "@images/logo/sign.3x.png";
import SignPath from "@images/logo/sign.png";

import "../styles.scss";

const NYBanner = () =>
  (
    <div className="banner-block">
      <img
        itemProp="image"
        src={SignPath}
        srcSet={`${SignPath}, ${Sign2xPath} 2x, ${Sign3xPath} 3x`}
        alt="logo"
        className="banner-block__sign"
      />
      <h4 className="banner-block__header">Работа Lunaro в праздники</h4>
      <span className="banner-block__text">
        <p>
          &nbsp;31&nbsp;декабря&nbsp;ждём&nbsp;вас&nbsp;с&nbsp;9:00&nbsp;до&nbsp;21:00&nbsp;по&nbsp;мск. 1&nbsp;января&nbsp;—&nbsp;с&nbsp;12:00&nbsp;до&nbsp;01:00.
        </p>
        <p>
          &nbsp;Со&nbsp;2&nbsp;января&nbsp;работаем&nbsp;в&nbsp;обычном&nbsp;режиме —&nbsp;с&nbsp;9:00&nbsp;до&nbsp;01:00&nbsp;по&nbsp;мск.
        </p>
        <p>
          Команда&nbsp;и&nbsp;эксперты&nbsp;Lunaro&nbsp;поздравляют
          <br />
          вас&nbsp;с&nbsp;Новым&nbsp;годом&nbsp;❄
        </p>
      </span>
    </div>
  );

export default NYBanner;
