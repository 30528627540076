import { OrderEnum, SortByEnum } from "@/autoGeneratedGlobalTypes";

export const mapSortingIdToRequestValue = (sortingValueId: number | undefined) => {
  switch (sortingValueId) {
    case undefined:
    case 0:
      return {
        sortBy: SortByEnum.default,
        order: OrderEnum.desc,
      };
    case 1:
      return {
        sortBy: SortByEnum.rate,
        order: OrderEnum.asc,
      };
    case 2:
      return {
        sortBy: SortByEnum.rate,
        order: OrderEnum.desc,
      };
    case 3:
      return {
        sortBy: SortByEnum.rating,
        order: OrderEnum.desc,
      };
    default:
      return undefined;
  }
};
