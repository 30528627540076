import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from "react";

import { useLazyQuery } from "@apollo/client";
import _omit from "lodash/omit";

import Breadcrumbs from "@/components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@/components/common/Breadcrumbs/types";
import { ExpertsType } from "@/components/common/expertCard/types";
import PromoBlock from "@/components/common/promoBlock";
import SeoHeaders from "@/components/common/seoHeaders";
import { COMMON_PREVIEW_IMAGE_URL } from "@/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { useBanners } from "@/hooks/useBanners";
import { useMarketingHook } from "@/marketing/marketingHook";
import { isBrowser } from "@/utils/env";

import { DEFAULT_DESCRIPTION, DEFAULT_FILTERS, DEFAULT_TITLE } from "../constants";
// import FilterButton from "../filterButton";
import { GET_EXPERTS } from "../graphql/GET_EXPERTS";
import Listing from "../listing";
import Recents from "../recents";
import ScopeFilter from "../scopeFilter";
import SeoText from "../seoText";
import Sidebar from "../sidebar";
import SkillBlock from "../skillBlock";
import {
  FilterChangeFunction, FilterClearFunction, ReservedFiltersEnum, SelectedFiltersType,
} from "../types";
import { getRequestVariables } from "../utils/filterUtils";
import { urlObserver } from "../utils/urlObserver";

import { OTHER_FILTERS, PAGE_SIZE } from "./constants";
import { CatalogContainerProps } from "./types";

import "../styles.scss";

const CatalogContainer = ({ pageContext, location }: CatalogContainerProps) => {
  const {
    skills,
    scopes,
    expertsInitial,
    seoId,
    title = DEFAULT_TITLE,
    ogTitle = DEFAULT_TITLE,
    description = DEFAULT_DESCRIPTION,
    seoText,
    header,
    breadcrumbDisplayName,
    breadcrumbs,
    url,
    nightBannerData,
    catalogBannerData,
    wideBannerData,
  } = pageContext;
  const { checkIsWideBannerActive } = useBanners();
  const { marketingTrackFlocktoryExchange, marketingTrackGet4clickExchange, marketingViewCatalog } = useMarketingHook();
  const { email } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);
  const [experts, setExperts] = useState<ExpertsType>(expertsInitial);
  const [lastLoadedPage, setLastLoadedPage] = useState<number | null>(null);
  const lastQueryEndCursor = useRef(experts.pageInfo.endCursor);

  // todo: make UI query errors handling
  const [
    getCursor,
    { data: pageCursorData, loading: pageCursorLoading, error: pageCursorError },
  ] = useLazyQuery(
    GET_EXPERTS,
    { fetchPolicy: "network-only" },
  );
  const [getExperts, { data, loading, error }] = useLazyQuery(GET_EXPERTS, { fetchPolicy: "network-only" });
  const [
    getMoreExperts,
    { data: loadMoreData, loading: loadMoreLoading, error: loadMoreError },
  ] = useLazyQuery(GET_EXPERTS);

  // Don't use setSelectedFilters anywhere except for synchronizing with URL observer
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersType>(
    isBrowser() ? urlObserver.getState() : {},
  );

  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState<boolean>(false);

  const filterChangeHandler: FilterChangeFunction = (
    filterId: ReservedFiltersEnum,
    valueIds: number[],
  ) => {
    // todo: maybe move this logic to observer as addParameter/removeParameter
    if (valueIds.length > 0) {
      urlObserver.update({
        ..._omit(selectedFilters, [ReservedFiltersEnum.Page]),
        [filterId]: valueIds,
      });
    } else {
      urlObserver.update({
        ..._omit(selectedFilters, [filterId, ReservedFiltersEnum.Page]),
      });
    }
  };

  const onFilterClear: FilterClearFunction = () => {
    urlObserver.update({});
  };

  const loadExperts = useCallback((cursor: string | null = null) => {
    const queryVariables = getRequestVariables(selectedFilters);
    const pageNumber = selectedFilters[ReservedFiltersEnum.Page]?.[0] ?? 1;
    let cursorToLoadFrom = cursor;
    if (!cursorToLoadFrom && lastLoadedPage === pageNumber - 1) {
      cursorToLoadFrom = lastQueryEndCursor.current;
    }
    if (pageNumber !== 1 && !cursorToLoadFrom) {
      getCursor({
        variables: { ...queryVariables, first: PAGE_SIZE * (pageNumber - 1) },
      });
    } else {
      setLastLoadedPage(pageNumber);
      getExperts({
        variables: { ...queryVariables, after: cursorToLoadFrom },
      });
    }
  }, [
    getCursor,
    getExperts,
    lastLoadedPage,
    selectedFilters,
  ]);

  const loadMoreExperts = () => {
    if (lastQueryEndCursor.current !== "") {
      getMoreExperts({
        variables: { ...getRequestVariables(selectedFilters), after: lastQueryEndCursor.current },
      });
      setLastLoadedPage(null);
    }
  };

  useEffect(() => {
    // Synchronizing with URL observer
    const unsubscribe = urlObserver.subscribe(setSelectedFilters);
    urlObserver.reset();
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (location.state?.showBannerFromFlocktory && email !== undefined && !isExpert) {
      marketingTrackGet4clickExchange(email || "");
      marketingTrackFlocktoryExchange(email || "xname@flocktory.com");
    }
  }, [email,
    isExpert,
    location.state?.showBannerFromFlocktory,
    marketingTrackFlocktoryExchange,
    marketingTrackGet4clickExchange]);

  // First load and load after filters changed
  useEffect(() => {
    loadExperts();
  }, []);

  // Load after paging is clicked and correct cursor is received from the server
  useEffect(() => {
    if (pageCursorData && !pageCursorLoading && !pageCursorError) {
      loadExperts(pageCursorData.getExperts.pageInfo.endCursor);
    }
  }, [pageCursorData, pageCursorLoading, pageCursorError]);

  const onLoadMoreClick = () => {
    // Quietly updating observer so component props don't refresh
    // This is needed so component doesn't refresh but just adds experts to the end of the list
    urlObserver.update(
      {
        ...selectedFilters,
        [ReservedFiltersEnum.Page]:
          [urlObserver.getState()[ReservedFiltersEnum.Page]?.[0] ?? 1 + 1],
      },
      true,
    );
    loadMoreExperts();
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setExperts(data.getExperts);
      lastQueryEndCursor.current = data.getExperts.pageInfo.endCursor;
    } else if (error) {
      setLastLoadedPage(null);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (loadMoreData && !loadMoreLoading && !loadMoreError) {
      setExperts({
        ...loadMoreData.getExperts,
        edges: experts.edges.concat(loadMoreData.getExperts.edges),
      });
      lastQueryEndCursor.current = loadMoreData.getExperts.pageInfo.endCursor;
    }
  }, [loadMoreData, loadMoreLoading, loadMoreError, experts.edges]);

  useEffect(() => {
    marketingViewCatalog();
  }, []);

  const isFiltered = Object.keys(selectedFilters).filter((f) =>
    !DEFAULT_FILTERS.map((d) =>
      d.filter.toString()).includes(f)).length > 0;

  const wideBannerBannerIsShownOnCatalog = checkIsWideBannerActive(
    wideBannerData.wideBannerIsShownOnCatalog,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
    wideBannerData.wideBannerIsShownWithFreeSession,
  );
  return (
    <>
      { wideBannerBannerIsShownOnCatalog && <PromoBlock wideBannerData={wideBannerData} />}
      <div className="bg--white">
        <div className="page-width">
          <SeoHeaders
            title={title}
            ogTitle={ogTitle}
            description={description}
            url={url}
            imageUrl={COMMON_PREVIEW_IMAGE_URL}
          />
          <div className="catalog__page-width">
            <Breadcrumbs
              seoId={seoId}
              url={url}
              breadcrumbDisplayName={breadcrumbDisplayName}
              breadcrumbs={breadcrumbs}
              urlPrefix={BreadcrumbUrlPrefixEnum.Catalog}
            />
            <SkillBlock
              skills={skills}
              selectedSkills={selectedFilters[ReservedFiltersEnum.Skill]}
              header={header}
            />
          </div>
          <Sidebar
            skills={skills}
            scopes={scopes}
            otherFilters={OTHER_FILTERS}
            selectedFilters={selectedFilters}
            isMobileFilterOpen={isMobileFilterOpen}
            expertsCount={experts?.totalCount ?? 0}
            setIsMobileFilterOpen={setIsMobileFilterOpen}
            onChange={filterChangeHandler}
            onFilterClear={onFilterClear}
          />
          <Listing
            catalogBannerData={catalogBannerData}
            selectedFilters={selectedFilters}
            experts={experts}
            isLoading={loading}
            isMoreLoading={loadMoreLoading}
            onChange={filterChangeHandler}
            onLoadMoreClick={onLoadMoreClick}
            nightBannerData={nightBannerData}
          />
          <ScopeFilter
            scopes={scopes}
            selected={selectedFilters[ReservedFiltersEnum.Scope]}
            selectedFilters={selectedFilters}
            onChange={filterChangeHandler}
          />

          {/* todo: uncomment after fixing https://3.basecamp.com/5069474/buckets/26069459/todos/6688759792 */}
          {/* <FilterButton isFiltered={isFiltered} setIsMobileFilterOpen={setIsMobileFilterOpen} /> */}
          <Recents />
          <SeoText text={seoText} />
        </div>
      </div>
    </>
  );
};

export default CatalogContainer;
